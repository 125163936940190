var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    this.target1 && this.target2
      ? _c("section", { staticClass: "row-wrap" }, [
          _c("div", { staticClass: "detail" }),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("div", { staticClass: "user" }, [
                _c(
                  "div",
                  { staticClass: "user__main" },
                  [
                    _c(
                      "div",
                      { staticClass: "user__photo" },
                      [
                        _c(
                          "v-avatar",
                          {
                            staticClass: "user__photo__img hidden-pad-down",
                            attrs: { size: "58" }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.getProfilePhotoSrc(
                                  _vm.target1.Constellation
                                )
                              }
                            })
                          ]
                        ),
                        _c(
                          "v-avatar",
                          {
                            staticClass: "user__photo__img hidden-pad-up",
                            attrs: { size: "32" }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.getProfilePhotoSrc(
                                  _vm.target1.Constellation
                                )
                              }
                            })
                          ]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "user__info" }, [
                      _c("div", { staticClass: "user__info__name" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$NameFormat(
                                _vm.showType == "type2"
                                  ? this.target2.Name
                                  : this.target1.Name,
                                10
                              )
                            ) +
                            "\n            "
                        )
                      ])
                    ]),
                    _vm._m(0),
                    _c(
                      "div",
                      { staticClass: "user__photo" },
                      [
                        _c(
                          "v-avatar",
                          {
                            staticClass: "user__photo__img hidden-pad-down",
                            attrs: { size: "58" }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.getProfilePhotoSrc(
                                  _vm.target2.Constellation
                                )
                              }
                            })
                          ]
                        ),
                        _c(
                          "v-avatar",
                          {
                            staticClass: "user__photo__img hidden-pad-up",
                            attrs: { size: "32" }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.getProfilePhotoSrc(
                                  _vm.target2.Constellation
                                )
                              }
                            })
                          ]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "user__info" }, [
                      _c("div", { staticClass: "user__info__name" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$NameFormat(
                                _vm.showType == "type2"
                                  ? this.target1.Name
                                  : this.target2.Name,
                                10
                              )
                            ) +
                            "\n            "
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "user__infoShow ml_8 pt_4",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.userMobileInfoOpen = !_vm.userMobileInfoOpen
                          }
                        }
                      },
                      [
                        _c("img", {
                          style: _vm.userMobileInfoOpen
                            ? "transform:rotate(180deg)"
                            : "",
                          attrs: { src: require("@/assets/icon_downward.svg") }
                        })
                      ]
                    ),
                    _c("v-spacer", { staticClass: "hidden-pad-down" }),
                    _c("div", { staticClass: "user__change hidden-pad-down" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/icon_change.svg"),
                          alt: ""
                        },
                        on: { click: _vm.toListDBL }
                      })
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.userMobileInfoOpen,
                        expression: "userMobileInfoOpen"
                      }
                    ],
                    staticClass: "user__sub"
                  },
                  [
                    _c("div", { staticClass: "user__info__birth" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.showType == "type2"
                              ? _vm.target2.BirthCountry +
                                  ", " +
                                  _vm.target2.BirthCity
                              : _vm.target1.BirthCountry +
                                  ", " +
                                  _vm.target1.BirthCity
                          )
                      ),
                      _c("br"),
                      _vm._v(
                        "\n           " +
                          _vm._s(
                            _vm.birthFormat(
                              _vm.showType == "type2"
                                ? _vm.target2.BirthTime
                                : _vm.target1.BirthTime
                            )
                          ) +
                          " \n          "
                      )
                    ]),
                    _c("div", { staticClass: "user__div" }),
                    _c("div", { staticClass: "user__info__birth" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.showType == "type2"
                              ? _vm.target1.BirthCountry +
                                  ", " +
                                  _vm.target1.BirthCity
                              : _vm.target2.BirthCountry +
                                  ", " +
                                  _vm.target2.BirthCity
                          )
                      ),
                      _c("br"),
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.birthFormat(
                              _vm.showType == "type2"
                                ? _vm.target1.BirthTime
                                : _vm.target2.BirthTime
                            )
                          ) +
                          "\n          "
                      )
                    ])
                  ]
                )
              ]),
              _c("v-spacer"),
              _vm.Data1
                ? _c(
                    "div",
                    { staticClass: "astrolabeDBL" },
                    [
                      _c("ul", { staticClass: "pan_type" }, [
                        _c(
                          "li",
                          {
                            class: { active: _vm.showType == "type1" },
                            on: {
                              click: function($event) {
                                return _vm.changeType1()
                              }
                            }
                          },
                          [_vm._v("\n            比較 1\n          ")]
                        ),
                        _c(
                          "li",
                          {
                            class: { active: _vm.showType == "type2" },
                            on: {
                              click: function($event) {
                                return _vm.changeType2()
                              }
                            }
                          },
                          [_vm._v("\n            比較 2\n          ")]
                        ),
                        _c(
                          "li",
                          {
                            class: { active: _vm.showType == "type3" },
                            on: {
                              click: function($event) {
                                return _vm.changeType3()
                              }
                            }
                          },
                          [_vm._v("\n            組合\n          ")]
                        )
                      ]),
                      _c("div", { staticClass: "astrolabeDBL__wrap" }, [
                        _c(
                          "div",
                          { staticClass: "swiper" },
                          [
                            _c(
                              "swiper",
                              {
                                attrs: {
                                  id: "SW",
                                  options: _vm.swiperAstrolabe
                                }
                              },
                              _vm._l(_vm.style, function(item) {
                                return _c(
                                  "swiper-slide",
                                  {
                                    key: item.id,
                                    staticClass: "astrolabeDBL__item"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "astrolabeDBL__bg",
                                        attrs: { id: "astrolabeDBL__bg" }
                                      },
                                      [
                                        _vm.dataChange
                                          ? _c("canvasAstrolabe", {
                                              staticClass: "canvas-pan",
                                              attrs: {
                                                width: _vm.paramPan.width,
                                                configItem: _vm.panConfigItem,
                                                panStyle: item.name,
                                                type: _vm.paramPan.type,
                                                data1: _vm.Data1.AstralRadians,
                                                data2: _vm.Data2
                                                  ? _vm.Data2.AstralRadians
                                                  : null,
                                                Ret1: _vm.Data1.RetAstralSet,
                                                role: "DBL"
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              }),
                              1
                            ),
                            _vm.style.length > 1
                              ? _c("div", { staticClass: "switch" }, [
                                  _c("div", {
                                    staticClass: "swiper-button-prev",
                                    attrs: { slot: "button-prev" },
                                    slot: "button-prev"
                                  }),
                                  _c("div", {
                                    staticClass: "swiper-pagination",
                                    attrs: { slot: "pagination" },
                                    slot: "pagination"
                                  }),
                                  _c("div", {
                                    staticClass: "swiper-button-next",
                                    attrs: { slot: "button-next" },
                                    slot: "button-next"
                                  })
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "astrolabeDBL__setting",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.openSetup()
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/icon_setting.svg"),
                              alt: ""
                            }
                          })
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "astrolabeDBL__change hidden-pad-up",
                          on: { click: _vm.toListDBL }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/icon_change.svg"),
                              alt: ""
                            }
                          })
                        ]
                      ),
                      _c("SetAstrolabe", {
                        attrs: { role: "DBL", activeSetup: _vm.activeSetup },
                        on: {
                          close: function($event) {
                            _vm.activeSetup = false
                          },
                          dataChange: function($event) {
                            return _vm.dataChangeHandler($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "user__div" }, [
      _c("img", {
        attrs: { src: require("@/assets/雙人合盤/好友分隔.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="interpret">
    <!-- 星座解讀 -->
    <div class="interpret__star">
      <div class="interpret__star__title">
        {{ type }}
        <img src="@/assets/雙人合盤/情侶.svg" alt="" v-if="type == '感情'" />
        <img src="@/assets/雙人合盤/親子.svg" alt="" v-if="type == '親子'" />
        <img src="@/assets/雙人合盤/職場.svg" alt="" v-if="type == '職場'" />
        合盤
      </div>
      <div class="interpret__star__line"></div>
      <div class="interpret__star__subtitle">
        <div class="interpret__star__subtitle__space"></div>
        <div class="interpret__star__subtitle__info">
          <span style="text-align: right">{{
            $NameFormat(showType == "type2" ? target2.Name : target1.Name, 8)
          }}</span>
          <div class="interpret__star__subtitle__impact">
            {{ showType == "type3" ? "互動" : "影響" }}
          </div>
          <span>{{
            $NameFormat(showType == "type2" ? target1.Name : target2.Name, 8)
          }}</span>
        </div>
        <div
          class="interpret__star__subtitle__sortBtn"
          :class="{
            sortMode: sortMode,
            sortDesc: sortDesc,
          }"
          @click="sortHandler()"
        >
          <img src="@/assets/雙人合盤/off.svg" alt="" />
        </div>
      </div>
      <div class="interpret__star__content">
        <div
          class="interpret__star__content__item mt_6"
          v-for="(item, index) in showInterpretations"
          :key="index"
        >
          <div class="interpret__star__content__item__main">
            <div>
              <img :src="findImg(item.AstralNames[0])" alt="" />
              <span class="ml-1">{{ item.AstralNames[0] }}</span>
              <span
                class="interpret__star__content__item__main__AspectName pl-1 pr-1"
                >{{ item.AspectName }}</span
              >
              <img :src="findImg(item.AstralNames[1])" alt="" />
              <span class="ml-1">{{ item.AstralNames[1] }}</span>
            </div>
            <div>
              <!-- item.item.AspectPower  -->
              <img
                src="@/assets/雙人合盤/等級.svg"
                alt=""
                v-for="(i, ind) in item.AspectPower"
                :key="ind"
              />
            </div>
          </div>
          <div class="interpret__star__content__item__content mt_6">
            {{ item.Content }}
          </div>
        </div>
      </div>
      <transition name="fade">
        <div class="interpret__star__notice" v-show="interpretTabNotice">
          <img src="@/assets/上下滑動.svg" alt />
          <span class="mt-3">上下滑動瀏覽解讀</span>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: ["type", "target1", "target2", "SynastrySnapshotID", "showType"],
  data() {
    return {
      planets: require("@/js/planets"),
      Interpretations: [],
      sortDesc: false, //false正序、true倒序
      sortMode: false,
      interpretTabNotice: true,
    };
  },
  mounted() {
    this.getInterpretations();
    setTimeout(() => {
      this.interpretTabNotice = false;
    }, 3000);
  },
  computed: {
    showInterpretations() {
      if (!this.sortMode) return this.Interpretations;
      return JSON.parse(JSON.stringify(this.Interpretations)).sort((a, b) => {
        return this.sortDesc
          ? a.AspectPower - b.AspectPower
          : b.AspectPower - a.AspectPower;
      });
    },
    Type() {
      switch (this.showType) {
        case "type1":
          return 1;
        case "type2":
          return 2;
        case "type3":
          return 0;
      }
    },
  },
  methods: {
    /** 取得合盤解讀 */
    async getInterpretations() {
      let DetailNeed = this.$store.state.DBL.mainPlanetList.concat(
        this.$store.state.DBL.planetList
      );
      let detailNeed_fixed = [
        {
          Astral: "Sun",
          Degree: DetailNeed[0].val,
          Visable: DetailNeed[0].toggleSelect,
        },
        {
          Astral: "Moon",
          Degree: DetailNeed[1].val,
          Visable: DetailNeed[1].toggleSelect,
        },
        {
          Astral: "Mercury",
          Degree: DetailNeed[2].val,
          Visable: DetailNeed[2].toggleSelect,
        },
        {
          Astral: "Venus",
          Degree: DetailNeed[3].val,
          Visable: DetailNeed[3].toggleSelect,
        },
        {
          Astral: "Mars",
          Degree: DetailNeed[4].val,
          Visable: DetailNeed[4].toggleSelect,
        },
        {
          Astral: "Jupiter",
          Degree: DetailNeed[5].val,
          Visable: DetailNeed[5].toggleSelect,
        },
        {
          Astral: "Saturn",
          Degree: DetailNeed[6].val,
          Visable: DetailNeed[6].toggleSelect,
        },
        {
          Astral: "Uranus",
          Visable: DetailNeed[18].toggleSelect,
        },
        {
          Astral: "Neptune",
          Visable: DetailNeed[19].toggleSelect,
        },
        {
          Astral: "Pluto",
          Visable: DetailNeed[20].toggleSelect,
        },
        {
          Astral: "Ascendant",
          Visable: DetailNeed[21].toggleSelect,
        },
        {
          Astral: "Midheaven",
          Visable: DetailNeed[22].toggleSelect,
        },
        {
          Astral: "Chiron",
          Visable: DetailNeed[23].toggleSelect,
        },
        {
          Astral: "Ceres",
          Visable: DetailNeed[24].toggleSelect,
        },
        {
          Astral: "Pallas",
          Visable: DetailNeed[25].toggleSelect,
        },
        {
          Astral: "Juno",
          Visable: DetailNeed[26].toggleSelect,
        },
        {
          Astral: "Vesta",
          Visable: DetailNeed[27].toggleSelect,
        },
        {
          Astral: "NoNode",
          Visable: DetailNeed[28].toggleSelect,
        },
        {
          Astral: "SoNode",
          Visable: DetailNeed[29].toggleSelect,
        },
        {
          Astral: "Lilith",
          Visable: DetailNeed[30].toggleSelect,
        },
        {
          Astral: "Fortune",
          Visable: DetailNeed[31].toggleSelect,
        },
        {
          Astral: "Vertex",
          Visable: DetailNeed[32].toggleSelect,
        },
        {
          Astral: "EaPoint",
          Visable: DetailNeed[33].toggleSelect,
        },
        {
          Astral: "Descendant",
          Visable: DetailNeed[34].toggleSelect,
        },
        {
          Astral: "IC",
          Visable: DetailNeed[35].toggleSelect,
        },
        {
          Phase: "0",
          Degree: DetailNeed[7].val,
          Visable: DetailNeed[7].toggleSelect,
        },
        {
          Phase: "90",
          Degree: DetailNeed[8].val,
          Visable: DetailNeed[8].toggleSelect,
        },
        {
          Phase: "120",
          Degree: DetailNeed[9].val,
          Visable: DetailNeed[9].toggleSelect,
        },
        {
          Phase: "180",
          Degree: DetailNeed[10].val,
          Visable: DetailNeed[10].toggleSelect,
        },
        {
          Phase: "60",
          Degree: DetailNeed[11].val,
          Visable: DetailNeed[11].toggleSelect,
        },
        {
          Phase: "30",
          Degree: DetailNeed[12].val,
          Visable: DetailNeed[12].toggleSelect,
        },
        {
          Phase: "45",
          Degree: DetailNeed[13].val,
          Visable: DetailNeed[13].toggleSelect,
        },
        {
          Phase: "72",
          Degree: DetailNeed[14].val,
          Visable: DetailNeed[14].toggleSelect,
        },
        {
          Phase: "135",
          Degree: DetailNeed[15].val,
          Visable: DetailNeed[15].toggleSelect,
        },
        {
          Phase: "144",
          Degree: DetailNeed[16].val,
          Visable: DetailNeed[16].toggleSelect,
        },
        {
          Phase: "150",
          Degree: DetailNeed[17].val,
          Visable: DetailNeed[17].toggleSelect,
        },
      ];
      console.warn("Type", this.Type);
      try {
        let res = await this.$API.POST_DblInterpretations({
          SynastrySnapshotID: this.SynastrySnapshotID,
          SetupConfigs: detailNeed_fixed,
          Type: this.Type,
        });
        this.Interpretations = res.Data.Interpretations;
      } catch {
        throw "API問題";
      }
    },
    findImg(Name) {
      let planet = this.planets.find((planet) => planet.name == Name);
      return planet.imageUrl;
    },
    sortHandler() {
      if (!this.sortMode) return (this.sortMode = true);
      this.sortDesc = !this.sortDesc;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
.interpret {
  width: 100%;
  height: 100%;
  &__star {
    padding: 18px 0 28px;
    @include rect(100%, 100%, $theme-color-3, 20px);
    @include align(flex-start, center);
    flex-direction: column;
    position: relative;
    &__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.08em;
      color: #868686;
      display: flex;
      align-items: center;
      padding: 0 32px;
      img {
        margin: 0 16px;
        font-size: 20px;
      }
    }
    &__subtitle {
      margin-bottom: 16px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.08em;
      color: #9b9b9b;
      padding: 0 32px;
      @include pad {
        margin-bottom: 14px;
        padding: 0 20px;
      }
      &__sortBtn {
        width: 28px;
        height: 28px;
        background: #f0f2f4;
        box-shadow: -1px -1px 4px #ffffff, -1px -1px 4px #ffffff,
          2px 2px 6px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        cursor: pointer;
        flex-shrink: 0;
        @include center;
        &.sortMode {
          box-shadow: 2px 2px 4px #ffffff,
            inset 4px 4px 10px rgba(0, 0, 0, 0.08);
        }
        &.sortDesc img {
          transform: rotate(180deg);
        }
      }
      &__space {
        width: 28px;
        flex-shrink: 0;
      }
      &__info {
        display: flex;
        > span {
          width: 5em;
          flex-shrink: 0;
          flex-grow: 0;
          font-size: 22px;
          white-space: nowrap;
          @include mobile {
            font-size: 18px;
            width: 4em;
          }
        }
      }
      &__impact {
        background: #f0f2f4;
        box-shadow: 2px 2px 4px #ffffff, inset 4px 4px 10px rgba(0, 0, 0, 0.08);
        border-radius: 6px;
        font-size: 18px;
        line-height: 18px;
        padding: 6.4px 14px;
        margin: 0 12px;
        @include center;
        color: rgb(23, 68, 92, 0.6);
        @include mobile {
          font-size: 14px;
          width: 60px;
        }
      }
    }

    &__line {
      margin: 32px 0;
      box-shadow: inset 4px 4px 10px 0 rgba(0, 0, 0, 0.08),
        3px 3px 4px 0 #ffffff;
      background-color: #f0f2f4;
      width: calc(100% - 64px);
      height: 3px;
      padding: 0 32px;
      @include pad {
        margin: 28px 0;
        padding: 0 20px;
        width: calc(100% - 40px);
      }
    }
    &__content {
      flex-grow: 0;
      width: 100%;
      height: 550px;
      padding: 0 30px 12px;
      overflow-y: auto;
      @include text-3(#292d2d);
      font-size: 18px;
      margin-bottom: 30px;
      line-height: 30px !important;
      transition: 0.5s;
      margin-top: 10px;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $theme-color-1;
      }
      @include pad {
        font-size: 16px;
        line-height: 26px !important;
        padding: 0 18px 12px;
      }
      &__item {
        width: 100%;
        background: #f0f2f4;
        box-shadow: -2px -2px 4px #ffffff, -2px -2px 4px #ffffff,
          4px 4px 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        padding: 16px 24px;
        @include mobile {
          padding: 16px;
        }
        &__main {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 18px;
          line-height: 26px;
          color: #798da5;
          @include mobile {
            font-size: 16px;
          }
          & > div {
            display: flex;
            align-items: center;
          }
          span {
            display: inline-block;
          }
          &__AspectName {
            margin: 0 13px;
            // min-width: 43px;
          }
          img {
            margin-top: 2px;
            width: 20px;
          }
        }
        &__content {
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.06em;
          color: #9b9b9b;
          @include mobile {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
    &__notice {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 620px;
      background: rgba(240, 242, 244, 0.8);
      backdrop-filter: blur(10px);
      z-index: 5;
      @include center;
      flex-direction: column;
      font-size: 14px;
      line-height: 14px;
      color: #ab956c;
      img {
        animation: upAndDown 1s infinite;
      }
      @keyframes upAndDown {
        0% {
          transform: translateY(0);
        }
        40% {
          transform: translateY(-32px);
        }
        100% {
          transform: translateY(0);
        }
      }
    }
  }
}
</style>

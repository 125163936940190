var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.todayIsNewMoon
    ? _c(
        "div",
        { staticClass: "wrap", attrs: { id: "newMoon" } },
        [
          _vm._m(0),
          _c("div", { staticClass: "newMoon" }, [
            _c("div", { staticClass: "newMoon__header" }, [
              _c("div", { staticClass: "newMoon__header__content" }, [
                _c("div", { staticClass: "newMoon__header__content__title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.queryitemConstellationCN(
                          _vm.todayIsNewMoon.Constellation
                        )
                      ) +
                      "新月\n        "
                  )
                ]),
                _c("div", [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm
                          .moment(_vm.todayIsNewMoon.StartTime)
                          .format("MM/DD HH:mm")
                      )
                    )
                  ]),
                  _vm._v("~"),
                  _c("span", [_vm._v(_vm._s(_vm.endTime))])
                ])
              ]),
              _c("img", {
                staticClass: "pic1",
                attrs: {
                  src: require("@/assets/月亮日記/筆記裝飾_月亮.svg"),
                  alt: ""
                }
              }),
              _c("img", {
                staticClass: "pic2",
                attrs: {
                  src: require("@/assets/月亮日記/筆記裝飾星星大.svg"),
                  alt: ""
                }
              }),
              _c("img", {
                staticClass: "pic3",
                attrs: {
                  src: require("@/assets/月亮日記/筆記裝飾星星小.svg"),
                  alt: ""
                }
              })
            ]),
            _c("div", { staticClass: "newMoon__body" }, [
              _c("div", {
                staticClass: "newMoon__body__main",
                domProps: { innerHTML: _vm._s(_vm.prayContent) }
              })
            ]),
            _vm._m(1),
            _c(
              "div",
              { staticClass: "newMoon__btn", on: { click: _vm.toMoonday } },
              [
                !_vm.enabledMoonNote
                  ? _c("span", [_vm._v("解鎖月亮日記")])
                  : _c("span", [_vm._v("前往月亮日記")])
              ]
            )
          ]),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _c(
                "Fixed_popup",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showTimeNotYet,
                      expression: "showTimeNotYet"
                    }
                  ]
                },
                [
                  _c("div", { staticClass: "cannot__box" }, [
                    _c("img", {
                      staticClass: "cannot__img",
                      attrs: {
                        src: require("@/assets/月亮日記/新月未到.svg"),
                        alt: ""
                      }
                    }),
                    _c("div", { staticClass: "cannot__title" }, [
                      _vm._v("祈願時間還沒到喔")
                    ]),
                    _c("div", { staticClass: "cannot__subtitle" }, [
                      _vm._v(
                        "\n          再等一下，等祈願時間到了，就可以許願囉\n        "
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "cannot__btn",
                        on: {
                          click: function($event) {
                            _vm.showTimeNotYet = false
                          }
                        }
                      },
                      [_vm._v("返回")]
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _c(
                "Fixed_popup",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showTimeOver,
                      expression: "showTimeOver"
                    }
                  ]
                },
                [
                  _c("div", { staticClass: "cannot__box" }, [
                    _c("img", {
                      staticClass: "cannot__img",
                      attrs: {
                        src: require("@/assets/月亮日記/新月已過.svg"),
                        alt: ""
                      }
                    }),
                    _c("div", { staticClass: "cannot__title" }, [
                      _vm._v("新月祈願已結束囉")
                    ]),
                    _c("div", { staticClass: "cannot__subtitle" }, [
                      _vm._v(
                        "\n          下次有相關新月祈願的活動，會再通知你喔\n        "
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "cannot__btn",
                        on: {
                          click: function($event) {
                            return _vm.$router.push("/moonday-list")
                          }
                        }
                      },
                      [_vm._v("\n          返回月亮日記\n        ")]
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h2", { staticClass: "st_title hidden-pad-down" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/月亮日記/月亮日記標題圖片.svg"),
            alt: ""
          }
        }),
        _vm._v("\n      關於新月祈願\n    ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "newMoon__notice" }, [
      _c("img", { attrs: { src: require("@/assets/提示燈泡.svg"), alt: "" } }),
      _c("div", [_vm._v("前往月亮日記許下你的願望，八小時內記得許願喔！")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "interpret" }, [
    _c(
      "div",
      { staticClass: "interpret__star" },
      [
        _c("div", { staticClass: "interpret__star__title" }, [
          _vm._v("\n      " + _vm._s(_vm.type) + "\n      "),
          _vm.type == "感情"
            ? _c("img", {
                attrs: { src: require("@/assets/雙人合盤/情侶.svg"), alt: "" }
              })
            : _vm._e(),
          _vm.type == "親子"
            ? _c("img", {
                attrs: { src: require("@/assets/雙人合盤/親子.svg"), alt: "" }
              })
            : _vm._e(),
          _vm.type == "職場"
            ? _c("img", {
                attrs: { src: require("@/assets/雙人合盤/職場.svg"), alt: "" }
              })
            : _vm._e(),
          _vm._v("\n      合盤\n    ")
        ]),
        _c("div", { staticClass: "interpret__star__line" }),
        _c("div", { staticClass: "interpret__star__subtitle" }, [
          _c("div", { staticClass: "interpret__star__subtitle__space" }),
          _c("div", { staticClass: "interpret__star__subtitle__info" }, [
            _c("span", { staticStyle: { "text-align": "right" } }, [
              _vm._v(
                _vm._s(
                  _vm.$NameFormat(
                    _vm.showType == "type2"
                      ? _vm.target2.Name
                      : _vm.target1.Name,
                    8
                  )
                )
              )
            ]),
            _c("div", { staticClass: "interpret__star__subtitle__impact" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.showType == "type3" ? "互動" : "影響") +
                  "\n        "
              )
            ]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$NameFormat(
                    _vm.showType == "type2"
                      ? _vm.target1.Name
                      : _vm.target2.Name,
                    8
                  )
                )
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "interpret__star__subtitle__sortBtn",
              class: {
                sortMode: _vm.sortMode,
                sortDesc: _vm.sortDesc
              },
              on: {
                click: function($event) {
                  return _vm.sortHandler()
                }
              }
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/雙人合盤/off.svg"), alt: "" }
              })
            ]
          )
        ]),
        _c(
          "div",
          { staticClass: "interpret__star__content" },
          _vm._l(_vm.showInterpretations, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "interpret__star__content__item mt_6"
              },
              [
                _c(
                  "div",
                  { staticClass: "interpret__star__content__item__main" },
                  [
                    _c("div", [
                      _c("img", {
                        attrs: {
                          src: _vm.findImg(item.AstralNames[0]),
                          alt: ""
                        }
                      }),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(item.AstralNames[0]))
                      ]),
                      _c(
                        "span",
                        {
                          staticClass:
                            "interpret__star__content__item__main__AspectName pl-1 pr-1"
                        },
                        [_vm._v(_vm._s(item.AspectName))]
                      ),
                      _c("img", {
                        attrs: {
                          src: _vm.findImg(item.AstralNames[1]),
                          alt: ""
                        }
                      }),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(item.AstralNames[1]))
                      ])
                    ]),
                    _c(
                      "div",
                      _vm._l(item.AspectPower, function(i, ind) {
                        return _c("img", {
                          key: ind,
                          attrs: {
                            src: require("@/assets/雙人合盤/等級.svg"),
                            alt: ""
                          }
                        })
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "interpret__star__content__item__content mt_6"
                  },
                  [_vm._v("\n          " + _vm._s(item.Content) + "\n        ")]
                )
              ]
            )
          }),
          0
        ),
        _c("transition", { attrs: { name: "fade" } }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.interpretTabNotice,
                  expression: "interpretTabNotice"
                }
              ],
              staticClass: "interpret__star__notice"
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/上下滑動.svg"), alt: "" }
              }),
              _c("span", { staticClass: "mt-3" }, [_vm._v("上下滑動瀏覽解讀")])
            ]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <section class="row-wrap" v-if="this.target1 && this.target2">
      <div class="detail">
        <!-- <Interpret
          :type="type"
          :showType="showType"
          :target1="target1"
          :target2="target2"
          :MemberSynastryId="$route.params.MemberSynastryId"
          ref="Interpret"
        ></Interpret> -->
      </div>
      <!--星盤-->
      <div class="content">
        <!-- //使用者資訊電腦版 -->
        <div class="user">
          <!-- 一般資訊 -->
          <div class="user__main">
            <div class="user__photo">
              <v-avatar size="58" class="user__photo__img hidden-pad-down">
                <img :src="getProfilePhotoSrc(target1.Constellation)" />
              </v-avatar>
              <v-avatar size="32" class="user__photo__img hidden-pad-up">
                <img :src="getProfilePhotoSrc(target1.Constellation)" />
              </v-avatar>
            </div>
            <div class="user__info">
              <div class="user__info__name">
                {{
                  $NameFormat(
                    showType == "type2" ? this.target2.Name : this.target1.Name,
                    10
                  )
                }}
              </div>
            </div>
            <div class="user__div">
              <img src="@/assets/雙人合盤/好友分隔.svg" alt="" />
            </div>
            <div class="user__photo">
              <v-avatar size="58" class="user__photo__img hidden-pad-down">
                <img :src="getProfilePhotoSrc(target2.Constellation)" />
              </v-avatar>
              <v-avatar size="32" class="user__photo__img hidden-pad-up">
                <img :src="getProfilePhotoSrc(target2.Constellation)" />
              </v-avatar>
            </div>
            <div class="user__info">
              <div class="user__info__name">
                {{
                  $NameFormat(
                    showType == "type2" ? this.target1.Name : this.target2.Name,
                    10
                  )
                }}
              </div>
            </div>
            <div
              class="user__infoShow ml_8 pt_4"
              @click.stop="userMobileInfoOpen = !userMobileInfoOpen"
            >
              <img
                src="@/assets/icon_downward.svg"
                :style="userMobileInfoOpen ? 'transform:rotate(180deg)' : ''"
              />
            </div>
            <v-spacer class="hidden-pad-down"></v-spacer>
            <div class="user__change hidden-pad-down">
              <img @click="toListDBL" src="@/assets/icon_change.svg" alt />
            </div>
          </div>
          <!-- 詳細資訊 -->
          <div class="user__sub" v-show="userMobileInfoOpen">
            <div class="user__info__birth">
              {{
                showType == "type2"
                  ? `${target2.BirthCountry}, ${target2.BirthCity}`
                  : `${target1.BirthCountry}, ${target1.BirthCity}`
              }}<br />
             {{
                birthFormat(
                  showType == "type2" ? target2.BirthTime : target1.BirthTime
                )
              }} 
            </div>
            <div class="user__div"></div>
            <div class="user__info__birth">
              {{
                showType == "type2"
                  ? `${target1.BirthCountry}, ${target1.BirthCity}`
                  : `${target2.BirthCountry}, ${target2.BirthCity}`
              }}<br />
              {{
                birthFormat(
                  showType == "type2" ? target1.BirthTime : target2.BirthTime
                )
              }}
            </div>
          </div>
        </div>
        <v-spacer></v-spacer>

        <!-- 切換模式End -->
        <div class="astrolabeDBL" v-if="Data1">
          <!-- 切換模式start -->
          <ul class="pan_type">
            <li @click="changeType1()" :class="{ active: showType == 'type1' }">
              比較 1
            </li>
            <li @click="changeType2()" :class="{ active: showType == 'type2' }">
              比較 2
            </li>
            <li @click="changeType3()" :class="{ active: showType == 'type3' }">
              組合
            </li>
          </ul>
          <div class="astrolabeDBL__wrap">
            <!-- swiper -->
            <div class="swiper">
              <swiper id="SW" :options="swiperAstrolabe">
                <swiper-slide
                  class="astrolabeDBL__item"
                  v-for="item in style"
                  :key="item.id"
                >
                  <div class="astrolabeDBL__bg" id="astrolabeDBL__bg">
                    <canvasAstrolabe
                      v-if="dataChange"
                      class="canvas-pan"
                      :width="paramPan.width"
                      :configItem="panConfigItem"
                      :panStyle="item.name"
                      :type="paramPan.type"
                      :data1="Data1.AstralRadians"
                      :data2="Data2 ? Data2.AstralRadians : null"
                      :Ret1="Data1.RetAstralSet"
                      role="DBL"
                    ></canvasAstrolabe>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="switch" v-if="style.length > 1">
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </div>
            </div>
            <!-- swiper end -->
          </div>
          <!-- 星盤設置開關  -->
          <div class="astrolabeDBL__setting" @click.stop="openSetup()">
            <img src="@/assets/icon_setting.svg" alt />
          </div>
          <div class="astrolabeDBL__change hidden-pad-up" @click="toListDBL">
            <img src="@/assets/icon_change.svg" alt />
          </div>
          <!-- 星盤設置  -->
          <SetAstrolabe
            role="DBL"
            :activeSetup="activeSetup"
            @close="activeSetup = false"
            @dataChange="dataChangeHandler($event)"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import canvasAstrolabe from "@/components/canvas-astrolabe.vue";
import SetAstrolabe from "@/components/SetAstrolabe.vue";
import Interpret from "@/components/雙人合盤/Interpret.vue";
import phaseMixin from "@/mixin/phaseMixin.js";
export default {
  mixins: [phaseMixin],
  components: {
    canvasAstrolabe,
    SetAstrolabe,
    Interpret,
  },
  data() {
    return {
      ready: false,
      Data1: null, //星盤設置設定檔
      Data2: null, //星盤設置設定檔
      target1: {},
      target2: {},
      activeDetailTab: "interpret",
      //強迫更新用
      dataChange: true,
      //星盤設置開關
      activeSetup: false,
      //星盤Swiper
      swiperAstrolabe: {
        initialSlide: 1,
        pagination: {
          el: ".swiper-pagination",
          bulletClass: "custom-bullet",
          bulletActiveClass: "custom-bullet-active",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          slideChangeTransitionEnd: () => {
            let swiper = document.getElementById("SW").swiper;
            let i = swiper.activeIndex;
            this.changeShow(i);
          },
        },
      },
      style: [
        // { name: "文字", displayName: "文字", show: false, id: "alert1" },
        // { name: "A32", displayName: "標準", show: false, id: "alert2" },
        // { name: "专业", displayName: "專業", show: false, id: "alert3" },
        { name: "JOE2", displayName: "專業", show: false, id: "alert4" },
        // { name: "文字", displayName: "文字", show: false, id: "alert1" },
      ],
      //星盤所需資料
      paramPan: {
        width: 520,
        maxWidth: 600, //最大屏幕宽度
        type: 1,
        data2: null,
        panStyle: "A32",
      },
      friendListCheck: [],
      showType: "type1",
      userMobileInfoOpen: false,
      type: "",
    };
  },
  computed: {
    panConfigItem() {
      //七個主要星
      let main = this.$store.state.DBL.mainPlanetList.slice(0, 7);
      //其他星
      let planet = this.$store.state.DBL.planetList;
      return [
        ...main.map((item) => (item.toggleSelect ? 1 : 0)), //星盤設置設定檔
        ...planet.map((item) => (item.toggleSelect ? 1 : 0)),
      ];
    },
    UserData() {
      return this.$store.state.userData; //星盤設置設定檔
    },
  },

  async created() {
    // if (
    //   !this.$route.params.MemberSynastryId ||
    //   !this.$route.params.target1 ||
    //   !this.$route.params.target2
    // ) {
    //   return this.$router.replace("/listDBL");
    // }
    if (process.env.NODE_ENV == "production") {
      this.$router.push({ path: "/" });
    }
    if (window.localStorage.getItem("login_status")) {
      this.$store.commit("updateLoginStatus", true);
    } else {
      this.$store.commit("updateLoginStatus", false);
    }
    if (window.innerWidth < 1263) {
      this.paramPan.width = 760 - 4;
    }
    if (window.innerWidth < 860) {
      this.paramPan.width = window.innerWidth - 4;
    }
    if (window.innerWidth < 768) {
      this.paramPan.width = window.innerWidth - 12;
    }
    try {
      let synastrysDataResult = await this.$API.GET_SynastrysData({
        MemberSynastryId: this.$route.params.MemberSynastryId,
      });

      //星體弧度資料
      this.target1 = synastrysDataResult.Data.Profile1;
      this.target2 = synastrysDataResult.Data.Profile2;
      //放人進去Data
      this.Data1 = synastrysDataResult.Data.Profile1.AstralInfo;
      this.Data2 = synastrysDataResult.Data.Profile2.AstralInfo;
    } catch (e) {
      console.error("API 取得合盤明細失敗", e);
    }

    this.ready = true;
  },
  methods: {
    changeShow(index) {
      this.canshow = [false, false, false];
      this.canshow[index] = true;
      setTimeout(() => {
        this.canshow = [false, false, false];
      }, 800);
    },
    //星盤設置開關
    openSetup() {
      this.activeSetup = !this.activeSetup;
    },
    //星盤設置更新
    dataChangeHandler($event) {
      this.dataChange = $event;
      if ($event) {
        //重新呼叫取得星盤解讀
        // this.getParams()
        this.$refs.Interpret.getInterpretations();
      }
    },
    changeType1() {
      this.showType = "type1";
      this.Data1 = this.target1.AstralInfo;
      this.Data2 = this.target2.AstralInfo;
      this.paramPan.type = 1;
      this.dataChange = false;
      setTimeout(() => {
        this.dataChange = true;
        this.$refs.Interpret.getInterpretations();
      }, 1);
    },
    changeType2() {
      this.showType = "type2";
      this.Data1 = this.target2.AstralInfo;
      this.Data2 = this.target1.AstralInfo;
      this.paramPan.type = 1;
      this.dataChange = false;
      setTimeout(() => {
        this.dataChange = true;
        this.$refs.Interpret.getInterpretations();
      }, 1);
    },
    changeType3() {
      this.showType = "type3";
      let compare1 = this.target1.AstralInfo;
      let compare2 = this.target2.AstralInfo;
      this.paramPan.type = 0;
      let composeData = [];
      compare1.AstralRadians.forEach((item, index) => {
        composeData[index] =
          (Number(compare1.AstralRadians[index]) +
            Number(compare2.AstralRadians[index])) /
          2;
        if (
          Number(compare1.AstralRadians[index]) <
            Number(compare2.AstralRadians[index]) &&
          Number(compare1.AstralRadians[index]) < 180
        ) {
          composeData[index] = (composeData[index] + 180) % 360;
        }
      });
      this.Data1 = {
        ...this.target1.AstralInfo,
        AstralRadians: composeData,
      };
      this.Data2 = null;
      this.paramPan.type = 0;
      this.dataChange = false;
      setTimeout(() => {
        this.dataChange = true;
        this.$refs.Interpret.getInterpretations();
      }, 1);
    },
    toListDBL() {
      this.$router.push("/listDBL");
    },
    getProfilePhotoSrc(constellation) {
      let obj = this.$Astro.find((item) => item.en == constellation);
      return obj ? obj.img : "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/DBL.scss";
</style>